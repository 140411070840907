import { render, staticRenderFns } from "./nonIndex.vue?vue&type=template&id=15db52aa&scoped=true"
import script from "./nonIndex.vue?vue&type=script&lang=js"
export * from "./nonIndex.vue?vue&type=script&lang=js"
import style0 from "./nonIndex.vue?vue&type=style&index=0&id=15db52aa&prod&lang=scss&scoped=true"
import style1 from "./nonIndex.vue?vue&type=style&index=1&id=15db52aa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15db52aa",
  null
  
)

export default component.exports