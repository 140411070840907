<template>
  <div class="container">
    <div class="type-select">
      <div class="item"
        :class="{ active: curType === 0 }"
        @click="handleSelect(0)">
        理赔列表
      </div>
      <!-- <div class="item"
        :class="{ active: curType === 2 }"
        v-if="!$store.state.userInfo.myAgentConcat"
        @click="handleSelect(2)">
        案件池
      </div> -->
      <div class="item"
        :class="{ active: curType === 1 }"
        v-if="!$store.state.userInfo.myAgentConcat"
        @click="handleSelect(1)">
        草稿箱
      </div>
    </div>
    <GeneralTable ref="GeneralTable"
      v-show="curType === 0"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="handleGoPath(`/routerClaimManagement/applyNonClaim?category=${1}&orderType=${21}`)"
      @del-change="deleteItem"
      @export-data="exportData"
      @import-click="importClick"
      tableName="claimManagement">
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box :statisticsList="statisticsList"
          v-loading="statisticsLoading" />
      </template>

      <template slot="operate-left">
        <el-button type="primary"
          size="mini"
          v-if="curType === 0"
          @click="handleDatchCloseCase">批量结案</el-button>
      </template>
      <template slot="caseFromSourceType"
        slot-scope="{item}">
        {{ item.caseFromSourceType == 1 ? '小程序一键报案' : item.caseFromSourceType == 2 ? '终端一键报案' : '--' }}
      </template>
      <template slot="statusName"
        slot-scope="{item}">
        <div :style="{ color: item.status == '1' ? '#FCA118': item.status == '2' ? '#0080FF' : '#CCCCCC' }">{{ item.statusName }}</div>
      </template>
      <template slot="policyNo"
        slot-scope="{item}">
        <div class="el-button el-button--text el-button--small"
          style="text-decoration:underline;padding: 0;"
          @click="handleGoInquiryPath(item)">{{ item.policyNo }}</div>
      </template>
      <template slot="caseNo"
        slot-scope="{item}">
        <div v-if="item.caseNo"
          class="el-button el-button--text el-button--small"
          style="text-decoration:underline;padding: 0;"
          @click="handleDropdownClick('details', item)">{{ item.caseNo }}</div>
        <div v-else>--</div>
      </template>
      <template slot="statusDesc"
        slot-scope="{item}">
        <div :class="[ 'status', statusClass[item.statusDesc] ]">{{ item.statusDesc }}</div>
      </template>
      <template slot="operate"
        v-if="!$store.state.userInfo.myAgentConcat"
        slot-scope="{item}">
        <div v-if="curType == 0">
          <el-button class="polecy-button"
            type="text"
            @click="handleDropdownClick('details', item)" v-if="handleHasPerms('M21-View-21-detail')"
            size="small">查看详情</el-button>
          <el-button class="polecy-button"
            type="text"
            @click="editData = item,isCaseDispatch = true;"
            size="small">调度记录</el-button>
          <el-dropdown @command="handleDropdownClick($event, item)">
            <span class="el-dropdown-link">
              更多
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="reportingHistory"
                :disabled="item.statusDesc != '已接案'">案件调度</el-dropdown-item>
              <el-dropdown-item command="evaluation"
                :disabled="item.statusDesc !== '已结案'">查看评价</el-dropdown-item>
              <el-dropdown-item command="caseReopen"
                :disabled="item.statusDesc !== '已结案'">案件重开</el-dropdown-item>
              <el-dropdown-item command="del" v-if="handleHasPerms('M21-View-21-del')">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </GeneralTable>
    <GeneralTable ref="GeneralTableTow"
      v-show="curType === 1"
      :dataList="claimRecordListTwo"
      :column="columnTwo"
      :option="optionTwo"
      :totalNum="totalNumTow"
      @search-change="getListTow"
      @del-change="deleteItem"
      tableName="claimManagementTwo">
      <template slot="statusName"
        slot-scope="{item}">
        <div :style="{ color: item.status == '1' ? '#FCA118': item.status == '2' ? '#0080FF' : '#CCCCCC' }">{{ item.statusName }}</div>
      </template>
      <template slot="policyNo"
        slot-scope="{item}">
        <div class="el-button el-button--text el-button--small"
          style="text-decoration:underline;padding: 0;"
          @click="handleGoInquiryPath(item)">{{ item.policyNo }}</div>
      </template>
      <template slot="caseNo"
        slot-scope="{item}">
        <div v-if="item.caseNo"
          class="el-button el-button--text el-button--small"
          style="text-decoration:underline;padding: 0;"
          @click="handleDropdownClick('details', item)">{{ item.caseNo }}</div>
        <div v-else>--</div>
      </template>
      <template slot="statusDesc"
        slot-scope="{item}">
        <div :class="[ 'status', statusClass[item.statusDesc] ]">{{ item.statusDesc }}</div>
      </template>

      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          type="text" v-if="handleHasPerms('M21-View-21-edit')"
          @click="handleDropdownClick('edit', item)"
          size="small">继续编辑</el-button>
        <el-button type="text" v-if="handleHasPerms('M21-View-21-del')"
          @click="handleDropdownClick('del', item)"
          size="small">删除</el-button>
      </template>
    </GeneralTable>
    <electronic-policy v-model="electronicVisible"
      :id="editData.id"
      @changeList="getList(oldSearchData, oldSearchData.current,oldSearchData.size, '', 'refresh')" />
    <HistoricalRecord ref="hisRd"
      :hisShow.sync="hisShow"></HistoricalRecord>
    <caseDispatchTable v-model="isCaseDispatch"
      :formData="{ caseId:editData.id}" />
    <scheduling :showDialog.sync="isScheduling"
      :id="editData.id"
      @changeList="changeList" />
    <caseReopen :showDialog.sync="isCaseReopen"
      :caseData="editData"
      @changeList="changeList" />
    <Evaluation v-model="showEvaluationDialog"
      :info="curEvaluation" />
    <batchCloseCase :showDialog.sync="showDatchCloseCase"
      :caseIds="availableCloseCaseIds"
      @changeList="changeList" />
    <optionPolicy :showDialog.sync="showOptionPolicy"
      :policyList.sync="gettingPolicyList"
      @changeList="changeList" />
    <alarmDialog v-model="isShowAlarm" :details="alarmDetails" @onHandleClick="handleOptionPolicy" />
    <dispatchDialog v-model="isShowDispatch" :details="alarmDetails" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import staffAddDialog from "@/views/SystemManagement/components/staff/staffAddDialog.vue";
import electronicPolicy from "@/views/policyManagement/components/electronicPolicy.vue";
import HistoricalRecord from "@/views/policyManagement/components/HistoricalRecord";
import caseDispatchTable from '@/views/claimManagement/components/dialogBox/caseDispatchTable.vue';
import alarmDialog from '@/views/claimManagement/components/dialogBox/alarmDialog.vue';
import dispatchDialog from '@/views/claimManagement/components/dialogBox/dispatchDialog.vue';
import caseReopen from '@/views/claimManagement/components/dialogBox/caseReopen.vue';
import scheduling from '@/views/claimManagement/components/dialogBox/scheduling.vue';
import { insNonClaimsCasePage, insNonClaimsCaseDraftPage, insClaimsCaseRemove, findEvaluate, insNonClaimsCaseDraftRemove, insNonClaimsCaseExportAll, insClaimsCaseAdopt, getNonClaimsDataStatistics } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import Evaluation from '@/views/claimManagement/components/evaluation.vue';
import batchCloseCase from '@/views/claimManagement/components/dialogBox/batchCloseCase.vue';
import optionPolicy from '@/views/claimManagement/components/dialogBox/optionPolicy.vue';
import dayjs from 'dayjs';
export default {
  name: 'nonClaimManagement',
  data() {
    return {
      //列表
      option: {
        isAdd: true, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isImport: false, // 导入 @import-click
        isExport: true,
        pageSize: 10,
        tableHeight: 'calc(100vh - 122px)', 
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [{
          label: '案件编号',
          prop: 'caseNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入案件编号",
        },
        {
          label: '报案号',
          prop: 'realCaseNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入报案号",
        },
        {
          label: '保单号',
          prop: 'policyNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入保单号",
        },
        // {
        //   label: '项目名称',
        //   prop: 'projectName',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'projectName') && this.$route.meta.policyRoute == 'CarInsurance'
        // },
        {
          label: '保险公司',
          prop: 'policyCompany',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入保险公司",
        },
        // {
        //   label: '保单所属部门',
        //   prop: 'salesmanDeptName',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'salesmanDeptName') && this.loginIdentity !== 'agent'
        // },
        // {
        //   label: '保单所属业务员',
        //   prop: 'policySalesman',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'policySalesman')
        // },
        // {
        //   label: '保单所属项目',
        //   prop: 'projectName',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'projectName') && this.loginIdentity !== 'agent'
        //   //  && this.$route.meta.policyRoute == 'CarInsurance'
        // },
        // {
        //   label: '投保人',
        //   prop: 'policyHolderName',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'policyHolderName')
        // },
        // {
        //   label: '被保险人',
        //   prop: 'insuredName',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'insuredName')
        // },
        // {
        //   label: '中介渠道',
        //   prop: 'policyCompany7',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'policyCompany')
        // }, 
        // {
        //   label: '业务渠道',
        //   prop: 'sourceChannel',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'sourceChannel')
        // },
        // {
        //   label: '方案名称',
        //   prop: 'schemeName',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入",
        //   isPermissions: dataPermissions('M21-View', 'schemeName') && this.loginIdentity !== 'agent'
        // }, 
        {
          label: '设备类型',
          prop: 'deviceType',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入类型",
        },
        {
          label: '车架号',
          prop: 'frameNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入车架号",
        },
        // {
        //   label: '车牌号',
        //   prop: 'plate',
        //   formType: 'input',
        //   maxLength: 32,
        //   clearable: true,
        //   placeholder: "请输入车架号",
        //   isPermissions: dataPermissions('M21-View', 'plate') && this.$route.meta.policyRoute != 'ConstructionMachinery'
        // },
        {
          label: '出险时间',
          prop: 'startDateBegin',
          format: 'yyyy.MM.dd',
          formType: 'daterange',
          clearable: true,
          prop: ['dangerDateBegin', 'dangerDateEnd'],
        },
        {
          label: '出险地点',
          prop: 'dangerDetailAddress',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入出险地点",
        }, {
          label: '录入时间',
          formType: 'daterange',
          format: 'yyyy.MM.dd',
          clearable: true,
          prop: ['createTimeBegin', 'createTimeEnd'],
        },
        {
          label: '报案人',
          prop: 'reportCasePersonName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入报案人",
        },
        {
          label: '报案时间',
          format: 'yyyy.MM.dd',
          formType: 'daterange',
          clearable: true,
          prop: ['reportCaseTimeBegin', 'reportCaseTimeEnd'],
        },
        {
          label: '修改时间',
          format: 'yyyy.MM.dd',
          formType: 'daterange',
          clearable: true,
          prop: ['updateTimeBegin', 'updateTimeEnd'],
          isPermissions: this.$route.meta.policyRoute != 'CarInsurance'
        },
        {
          label: '案件状态',
          prop: 'statusDesc',
          formType: 'select',
          filterable: true,
          placeholder: "请选择案件状态",
          selectList: [
            { value: '', text: '全部' },
            { value: '1', text: '已中止' },
            { value: '2', text: '已结案' },
            { value: '3', text: '案件重开' },
            { value: '4', text: '重开结案' },
            { value: '5', text: '已接案' },
            { value: '6', text: '验标中' },
            { value: '7', text: '定损中' },
            { value: '8', text: '核赔中' },
            { value: '9', text: '理算中' },
            { value: '10', text: '支付中' },],
        },
        {
          label: '跟进人',
          prop: 'caseFollower',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入跟进人",
        },
        {
          label: '录入员',
          prop: 'createUserName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入录入员",
        },]
      },
      //草稿箱
      optionTwo: {
        isAdd: false, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isImport: false, // 导入 @import-click
        isExport: false,
        tableHeight: 'calc(100vh - 122px)', 
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [
          {
            label: '报案号',
            prop: 'realCaseNo',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入报案号",
          },
          {
            label: '保单号',
            prop: 'policyNo',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入保单号",
          },
          {
            label: '保险公司',
            prop: 'policyCompany',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入保险公司",
          },
          // {
          //   label: '保单所属部门',
          //   prop: 'salesmanDeptName',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'salesmanDeptName') && this.loginIdentity !== 'agent'
          // },
          // {
          //   label: '保单所属业务员',
          //   prop: 'policySalesman',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'policySalesman')
          // },
          // {
          //   label: '保单所属项目',
          //   prop: 'projectName',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'projectName') && this.loginIdentity !== 'agent'
          //   //  && this.$route.meta.policyRoute == 'CarInsurance'
          // },
          // {
          //   label: '投保人',
          //   prop: 'policyHolderName',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'policyHolderName')
          // },
          // {
          //   label: '被保险人',
          //   prop: 'insuredName',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'insuredName')
          // },
          // {
          //   label: '中介渠道',
          //   prop: 'policyCompany7',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'policyCompany')
          // }, 
          // {
          //   label: '业务渠道',
          //   prop: 'sourceChannel',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'sourceChannel')
          // },
          // {
          //   label: '方案名称',
          //   prop: 'schemeName',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'schemeName') && this.loginIdentity !== 'agent'
          // }, 
          {
            label: '设备类型',
            prop: 'deviceType',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入类型",
          },
          {
            label: '车架号',
            prop: 'frameNo',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入车架号",
          },
          // {
          //   label: '车牌号',
          //   prop: 'plate',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入车牌号",
          //   isPermissions: dataPermissions('M21-View', 'plate') && this.$route.meta.policyRoute != 'ConstructionMachinery'
          // },
          // {
          //   label: '项目名称',
          //   prop: 'projectName',
          //   formType: 'input',
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   isPermissions: dataPermissions('M21-View', 'projectName') && (this.$route.meta.orderType == 2 && this.$route.meta.policyType == 2)
          // },
          {
            label: '出险时间',
            prop: 'startDateBegin',
            format: 'yyyy.MM.dd',
            formType: 'daterange',
            clearable: true,
            prop: ['dangerDateBegin', 'dangerDateEnd'],
          },
          {
            label: '出险地点',
            prop: 'dangerDetailAddress',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入出险地点",
          }, {
            label: '录入时间',
            formType: 'daterange',
            format: 'yyyy.MM.dd',
            clearable: true,
            prop: ['createTimeBegin', 'createTimeEnd'],
          },
          {
            label: '报案人',
            prop: 'reportCasePersonName',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入报案人",
          }, {
            label: '报案时间',
            format: 'yyyy.MM.dd',
            formType: 'daterange',
            clearable: true,
            prop: ['reportCaseTimeBegin', 'reportCaseTimeEnd'],
          },
          //   {
          //    label: '案件状态',
          //    prop: 'statusDesc',
          //    formType: 'select',
          //    filterable: true,
          //    placeholder: "请选择案件状态",
          //    selectList: [
          //      { value: '', text: '全部' },
          //      { value: '1', text: '已中止' },
          //      { value: '2', text: '已结案' },
          //      { value: '3', text: '案件重开' },
          //      { value: '4', text: '重开结案' },
          //      { value: '5', text: '已接案' },
          //      { value: '6', text: '验标中' },
          //      { value: '7', text: '定损中' },
          //      { value: '8', text: '核赔中' },
          //      { value: '9', text: '理算中' },
          //      { value: '10', text: '支付中' },],
          //    isPermissions: dataPermissions('M20-View', 'fromSourceType')
          //  },
          //  {
          //    label: '跟进人',
          //    prop: 'caseFollower',
          //    formType: 'input',
          //    maxLength: 32,
          //    clearable: true,
          //    placeholder: "请输入跟进人",
          //    isPermissions: dataPermissions('M21-View', 'caseFollower') && this.$route.meta.orderType == 2
          //  },
          //  {
          //    label: '录入员',
          //    prop: 'createUserName',
          //    formType: 'input',
          //    maxLength: 32,
          //    clearable: true,
          //    placeholder: "请输入录入员",
          //    isPermissions: dataPermissions('M21-View', 'createUserName')
          //  },
          {
            label: '案件编号',
            prop: 'caseNo',
            formType: 'input',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入案件编号",
          }
        ]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "案件编号",
          prop: "caseNo",
          align: 'center',
          width: 280,
          isShow: true,
          isSlot: true,
        },
        {
          label: "报案号",
          prop: "realCaseNo",
          align: 'center',
          width: 280,
          isShow: true,
        },
        {
          label: "保单号",
          prop: "policyNo",
          align: 'center',
          width: 280,
          isShow: true,
          isSlot: true,
        },
        // {
        //   label: "项目名称",
        //   prop: "projectName",
        //   align: 'center',
        //   width: 280,
        //   isShow: true,
        //   isSlot: true,
        //   isPermissions: this.$route.meta.policyRoute == 'CarInsurance'
        // },
        {
          label: "保险公司",
          prop: "policyCompany",
          align: 'center',
          width: 265,
          isShow: true,
        },
        // {
        //   label: "保单所属部门",
        //   prop: "salesmanDeptName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions:  dataPermissions('M21-View', 'salesmanDeptName')  && this.loginIdentity !== 'agent'
        // },
        // {
        //   label: "保单所属业务员",
        //   prop: "policySalesman",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'policySalesman')
        // },
        // {
        //   label: "保单所属项目",
        //   prop: "projectName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions:  dataPermissions('M21-View', 'projectName') && this.loginIdentity !== 'agent'
        //   //  && this.$route.meta.policyRoute == 'CarInsurance'  2.0.3取消限制
        // },
        // {
        //   label: "投保人",
        //   prop: "policyHolderName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'policyHolderName')
        // },
        {
          label: "被保险人",
          prop: "insuredName",
          align: 'center',
          width: 265,
          isShow: true,
        },
        // {
        //   label: "案件来源",
        //   prop: "caseFromSourceType",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isSlot: true,
        //   isPermissions: dataPermissions('M21-View', 'sourceChannel')
        // },
        // {
        //   label: "业务渠道",
        //   prop: "sourceChannel",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'sourceChannel')
        // },
        // {
        //   label: "方案名称",
        //   prop: "schemeName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'schemeName') && this.loginIdentity !== 'agent'
        // },
        {
          label: "出险设备",
          prop: "deviceType",
          align: 'center',
          isShow: true,
        },
        // {
        //   label: "车牌号",
        //   prop: "plate",
        //   align: 'center',
        //   isShow: true,
        //   width: 160,
        //   isPermissions: dataPermissions('M21-View', 'plate') && this.$route.meta.policyRoute != 'ConstructionMachinery'
        // },
        {
          label: "车架号",
          prop: "frameNo",
          align: 'center',
          isShow: true,
          width: 160,
        },
        {
          label: "出险时间",
          prop: "dangerDate",
          align: 'center',
          width: 200,
          isShow: true,
        },
        {
          label: "出险地点",
          prop: "dangerDetailAddress",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "事故原因",
          prop: "accidentCause",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "报案人",
          prop: "reportCasePersonName",
          align: 'center',
          isShow: true,
        },
        {
          label: "报案时间",
          prop: "reportCaseTime",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "案件状态",
          prop: "statusDesc",
          align: 'center',
          isShow: true,
          isSlot: true,
          width: 120,
        },
        {
          label: "赔付总金额",
          prop: "totalPayAmount",
          align: 'center',
          width: 120,
          isShow: true,
        },
        {
          label: "跟进人",
          prop: "caseFollower",
          align: 'center',
          isShow: true,
        },
        {
          label: "修改时间",
          prop: "updateTime",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "录入时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 330,
          fixed: 'right',
          isSlot: true,
        },
      ],
      columnTwo: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "报案号",
          prop: "realCaseNo",
          align: 'center',
          width: 280,
          isShow: true,
        },
        {
          label: "保单号",
          prop: "policyNo",
          align: 'center',
          width: 280,
          isShow: true,
          isSlot: true,
        },
        {
          label: "保险公司",
          prop: "policyCompany",
          align: 'center',
          width: 265,
          isShow: true,
        },
        // {
        //   label: "保单所属部门",
        //   prop: "salesmanDeptName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'salesmanDeptName') && this.loginIdentity !== 'agent'
        // },
        // {
        //   label: "保单所属业务员",
        //   prop: "policySalesman",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'policySalesman')
        // },
        // {
        //   label: "保单所属项目",
        //   prop: "projectName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'projectName') && this.loginIdentity !== 'agent'
        //   //  && this.$route.meta.policyRoute == 'CarInsurance'
        // },
        // {
        //   label: "投保人",
        //   prop: "policyHolderName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'policyHolderName')
        // },
        {
          label: "被保险人",
          prop: "insuredName",
          align: 'center',
          width: 265,
          isShow: true,
        },
        // {
        //   label: "中介渠道",
        //   prop: "policyCompany",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'policyCompany')
        // },
        // {
        //   label: "业务渠道",
        //   prop: "sourceChannel",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'sourceChannel')
        // },
        // {
        //   label: "方案名称",
        //   prop: "schemeName",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'schemeName') && this.loginIdentity !== 'agent'
        // },
        {
          label: "出险设备",
          prop: "deviceType",
          align: 'center',
          isShow: true,
        },
        // {
        //   label: "车牌号",
        //   prop: "plate",
        //   align: 'center',
        //   isShow: true,
        //   width: 160,
        //   isPermissions: dataPermissions('M21-View', 'plate') && ((this.$route.meta.orderType == 2 && this.$route.meta.policyType != 1) || this.$route.meta.orderType == 8)
        // },
        // {
        //   label: "项目名称",
        //   prop: "projectName",
        //   align: 'center',
        //   isShow: true,
        //   width: 160,
        //   isPermissions: dataPermissions('M21-View', 'projectName') && (this.$route.meta.orderType == 2 && this.$route.meta.policyType == 2)
        // },
        {
          label: "车架号",
          prop: "frameNo",
          align: 'center',
          isShow: true,
          width: 160,
        },
        {
          label: "出险时间",
          prop: "dangerDate",
          align: 'center',
          width: 200,
          isShow: true,
        },
        {
          label: "出险地点",
          prop: "dangerDetailAddress",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "报案人",
          prop: "reportCasePersonName",
          align: 'center',
          isShow: true,
        },
        {
          label: "报案时间",
          prop: "reportCaseTime",
          align: 'center',
          isShow: true,
          width: 265,
        },
        //   {
        //     label: "案件状态",
        //     prop: "statusDesc",
        //     align: 'center',
        //     isShow: true,
        //     isSlot: true,
        //     width: 120,
        //     isPermissions: dataPermissions('M20-View', 'policyState')
        //   },
        {
          label: "跟进人",
          prop: "caseFollower",
          align: 'center',
          isShow: true,
        },
        {
          label: "录入时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 330,
          fixed: 'right',
          isSlot: true,
        },
      ],
      columnPond: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "案件编号",
          prop: "caseNo",
          align: 'center',
          width: 280,
          isShow: true,
          isSlot: true,
        },
        //   {
        //     label: "报案号",
        //     prop: "realCaseNo",
        //     align: 'center',
        //     width: 280,
        //     isShow: true,
        //     isPermissions: dataPermissions('M21-View', 'realCaseNo') && this.$route.meta.policyRoute != 'CarInsurance'
        //   },
        {
          label: "保单号",
          prop: "policyNo",
          align: 'center',
          width: 280,
          isShow: true,
          isSlot: true,
        },
        // {
        //   label: "项目名称",
        //   prop: "projectName",
        //   align: 'center',
        //   width: 280,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'projectName') && this.$route.meta.policyRoute == 'CarInsurance'
        // },
        {
          label: "保险公司",
          prop: "policyCompany",
          align: 'center',
          width: 265,
          isShow: true,
        },
        {
          label: "保单所属部门",
          prop: "salesmanDeptName",
          align: 'center',
          width: 265,
          isShow: true,
        },
        {
          label: "保单所属业务员",
          prop: "policySalesman",
          align: 'center',
          width: 265,
          isShow: true,
        },
        {
          label: "保单所属项目",
          prop: "projectName",
          align: 'center',
          width: 265,
          isShow: true,
          //  && this.$route.meta.policyRoute == 'CarInsurance'
        },
        {
          label: "投保人",
          prop: "policyHolderName",
          align: 'center',
          width: 265,
          isShow: true,
        },
        {
          label: "被保险人",
          prop: "insuredName",
          align: 'center',
          width: 265,
          isShow: true,
        },
        // {
        //   label: "中介渠道",
        //   prop: "policyCompany",
        //   align: 'center',
        //   width: 265,
        //   isShow: true,
        //   isPermissions: dataPermissions('M21-View', 'policyCompany')
        // },
        {
          label: "业务渠道",
          prop: "sourceChannel",
          align: 'center',
          width: 265,
          isShow: true,
        },
        {
          label: "方案名称",
          prop: "schemeName",
          align: 'center',
          width: 265,
          isShow: true,
        },
        {
          label: "出险设备",
          prop: "deviceType",
          align: 'center',
          isShow: true,
        },
        {
          label: "车架号",
          prop: "frameNo",
          align: 'center',
          isShow: true,
          width: 160,
        },
        {
          label: "车牌号",
          prop: "plate",
          align: 'center',
          isShow: true,
          width: 160,
        },
        {
          label: "出险时间",
          prop: "dangerDate",
          align: 'center',
          width: 200,
          isShow: true,
        },
        {
          label: "出险地点",
          prop: "dangerDetailAddress",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "事故原因",
          prop: "accidentCause",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "案件来源",
          prop: "caseFromSourceType",
          align: 'center',
          width: 265,
          isShow: true,
          isSlot: true,
        },
        {
          label: "报案人",
          prop: "reportCasePersonName",
          align: 'center',
          isShow: true,
        },
        {
          label: "报案人电话",
          prop: "reportCasePhone",
          align: 'center',
          isShow: true,
          width: 265,
        },
        {
          label: "报案时间",
          prop: "reportCaseTime",
          align: 'center',
          isShow: true,
          width: 265,
        },
        //   {
        //     label: "案件状态",
        //     prop: "statusDesc",
        //     align: 'center',
        //     isShow: true,
        //     isSlot: true,
        //     width: 120,
        //     isPermissions: dataPermissions('M21-View', 'statusDesc') && this.$route.meta.policyRoute != 'CarInsurance'
        //   },
          {
            label: "跟进人",
            prop: "caseFollower",
            align: 'center',
            isShow: true,
          },
        {
          label: "区域经理",
          prop: "regionalManager",
          align: 'center',
          isShow: true,
        },
        //   {
        //     label: "修改时间",
        //     prop: "updateTime",
        //     align: 'center',
        //     isShow: true,
        //     width: 265,
        //     isPermissions: dataPermissions('M21-View', 'updateTime') && this.$route.meta.orderType == 2
        //   },
        //   {
        //     label: "录入时间",
        //     prop: "createTime",
        //     align: 'center',
        //     isShow: true,
        //     width: 265,
        //     isPermissions: dataPermissions('M21-View', 'createTime') && this.$route.meta.policyRoute != 'CarInsurance'
        //   },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 330,
          fixed: 'right',
          isSlot: true,
        },
      ],
      searchData: {},
      oldSearchData: {
      },
      oldSearchDataTow: {},
      //数据总量
      totalNum: 0,
      totalNumTow: 0,
      claimRecordList: [],
      claimRecordListTwo: [],
      operateTitle: '添加',
      isCaseDispatch: false,
      editData: {},
      isByUserVisible: false,
      roleId: '',
      passwordType: 'text',
      isImport: false,
      electronicVisible: false,
      hisShow: false,
      showEvaluationDialog: false,
      showDatchCloseCase: false,
      showOptionPolicy: false,
      availableCloseCaseIds: [],
      curEvaluation: {},
      isScheduling: false,
      isCaseReopen: false,
      statusClass: {
        '已接案': 'status1',
        '定损中': 'status2',
        '核算中': 'status2',
        '验标中': 'status2',
        '核赔中': 'status2',
        '理算中': 'status2',
        '支付中': 'status2',
        '已中止': 'status3',
        '已结案': 'status3',
        '重开结案': 'status4',
        '案件重开': 'status4'
      },
      curType: 0,
      gettingPolicyList: [],
      statisticsList: [
        {
          name: '理赔案件总数',
          value: 0
        },
        {
          name: '已结案件赔付金额',
          value: 0
        },
        {
          name: '未结案件预估金额',
          value: 0
        },
        {
          name: '总金额',
          value: 0,
        },
        // {
        //   name: '满期赔付率',
        //   value: 0,
        //   unit: '%'
        // }
      ],
      statisticsLoading: false,
      loginIdentity: '',
      isShowAlarm: false,
      alarmDetails: {},
      isShowDispatch: false,
      isShowWhose: false
    };
  },
  components: { GeneralTable, staffAddDialog, electronicPolicy, HistoricalRecord, caseDispatchTable, scheduling, Evaluation, caseReopen, batchCloseCase, optionPolicy, StatisticsBox, alarmDialog, dispatchDialog },
  created() {
    this.loginIdentity = this.$store.state.userInfo.loginIdentity;
  },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize, searcType, isReset) {
      let currentData = {};
      
      v.frameNo = v.frameNo || v.frameNoLike || v.keyword || '';
      // if (isReset == 'fuzzy' || (isReset == 'refresh' && this.oldSearchData.dangerDateBegin === undefined)) {
      //   let currentData2 = {
      //     createUserName: this.handleDataPermissions('M21-View', 'createUserName') ? v.keyword : '',
      //     frameNo: this.handleDataPermissions('M21-View', 'frameNo') ? v.keyword : '',
      //     caseFollower: this.handleDataPermissions('M21-View', 'caseFollower') ? v.keyword : '',
      //     realCaseNo: this.handleDataPermissions('M21-View', 'realCaseNo') ? v.keyword : '',
      //     // insuranceCompanyName: this.handleDataPermissions('M20-View', 'insuranceCompanyName') ? v.keyword : '',
      //     caseNo: this.handleDataPermissions('M21-View', 'caseNo') ? v.keyword : '',
      //     deviceType: this.handleDataPermissions('M21-View', 'deviceType') ? v.keyword : '',
      //     policyCompany: this.handleDataPermissions('M21-View', 'policyCompany') ? v.keyword : '',
      //     policyNo: this.handleDataPermissions('M21-View', 'policyNo') ? v.keyword : '',
      //     policyInsuredLike: this.handleDataPermissions('M21-View', 'policyInsuredLike') ? v.keyword : '',
      //     realCaseNo: this.handleDataPermissions('M21-View', 'realCaseNo') ? v.keyword : '', current: pageNum, size: pageSize,
      //   };
      //   if (isReset == 'refresh') {
      //     currentData2 = JSON.parse(JSON.stringify(this.oldSearchData));
      //   }
      //   this.oldSearchData = JSON.parse(JSON.stringify(currentData2));
      //   const { code, data } = await insClaimsCaseFastPage(currentData2);
      //   if (code === 200 && data.records && data.records.length) {
      //     this.claimRecordList = data.records.map(val => {
      //       val.roleId ? val.roleId = val.roleId.split(',') : '';
      //       // val.deptId = val.deptId.split(',');
      //       return val;
      //     });
      //     this.totalNum = data.total;
      //   } else {
      //     this.claimRecordList = [];
      //   }
      //   return false;
      // } else 
      
      if (v.dangerDateBegin && v.dangerDateBegin.split(' ').length === 1) {
        v.dangerDateBegin = dayjs(v.dangerDateBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.dangerDateEnd = dayjs(v.dangerDateEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      //'updateTimeBegin', 'updateTimeEnd'
      if (v.updateTimeBegin && v.updateTimeBegin.split(' ').length === 1) {
        v.updateTimeBegin = dayjs(v.updateTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.updateTimeEnd = dayjs(v.updateTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.reportCaseTimeBegin && v.reportCaseTimeBegin.split(' ').length === 1) {
        v.reportCaseTimeBegin = dayjs(v.reportCaseTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.reportCaseTimeEnd = dayjs(v.reportCaseTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.createTimeBegin && v.createTimeBegin.split(' ').length === 1) {
        v.createTimeBegin = dayjs(v.createTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.createTimeEnd = dayjs(v.createTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (isReset == 'reset') {
        currentData = JSON.parse(JSON.stringify(this.searchData));
      } else if (isReset == 'refresh') {
        currentData = JSON.parse(JSON.stringify(this.oldSearchData));
        console.log('refresh', currentData);
      } else {
        this.oldSearchData = JSON.parse(JSON.stringify({ ...this.searchData, ...v, dangerDateBegin: '' }));
        currentData = JSON.parse(JSON.stringify(this.searchData));
      }
      this.statisticsLoading = true;
      getNonClaimsDataStatistics({ ...currentData, ...v, current: pageNum, size: pageSize, condition: v.keyword, category: 1, orderType: 21 }).then(res => {
        this.statisticsLoading = false;
        this.statisticsList[0].value = res.data.total || 0;
        this.statisticsList[1].value = res.data.compensationAmount || 0;
        this.statisticsList[2].value = res.data.unCompensationAmount || 0;
        this.statisticsList[3].value = res.data.totalAmount || 0;
        // this.statisticsList[4].value = res.data.compensateRate || 0;
      }).catch(err => {
        this.statisticsLoading = false;
      });
      try {
        const { code, data } = await insNonClaimsCasePage({ ...currentData, ...v, current: pageNum, size: pageSize, condition: v.keyword, category: 1, orderType: 21 });
        if (code === 200 && data.records && data.records.length) {
          this.claimRecordList = data.records.map(val => {
            val.roleId ? val.roleId = val.roleId.split(',') : '';
            // val.deptId = val.deptId.split(',');
            return val;
          });
          this.totalNum = data.total;
        } else {
          this.claimRecordList = [];
          this.totalNum = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getListTow(v, pageNum, pageSize, searcType, isReset) {
      let currentData = {};
      v.frameNo = v.frameNo || v.frameNoLike || v.keyword || '';
      if (isReset == 'reset') {
      } else if (isReset == 'refresh') {
        currentData = JSON.parse(JSON.stringify(this.oldSearchDataTow));
      } else {
        this.oldSearchDataTow = JSON.parse(JSON.stringify({ ...v, dangerDateBegin: '' }));
        currentData = JSON.parse(JSON.stringify(this.searchData));
      }
      if (v.dangerDateBegin && v.dangerDateBegin.split(' ').length === 1) {
        v.dangerDateBegin = dayjs(v.dangerDateBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.dangerDateEnd = dayjs(v.dangerDateEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      //'updateTimeBegin', 'updateTimeEnd'
      if (v.updateTimeBegin && v.updateTimeBegin.split(' ').length === 1) {
        v.updateTimeBegin = dayjs(v.updateTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.updateTimeEnd = dayjs(v.updateTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.reportCaseTimeBegin && v.reportCaseTimeBegin.split(' ').length === 1) {
        v.reportCaseTimeBegin = dayjs(v.reportCaseTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.reportCaseTimeEnd = dayjs(v.reportCaseTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.createTimeBegin && v.createTimeBegin.split(' ').length === 1) {
        v.createTimeBegin = dayjs(v.createTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.createTimeEnd = dayjs(v.createTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      try {
        const { code, data } = await insNonClaimsCaseDraftPage({ ...v, ...currentData, current: pageNum, size: pageSize, condition: v.keyword, category: 1, orderType: 21 });

        if (code === 200 && data.records && data.records.length) {
          this.claimRecordListTwo = data.records.map(val => {
            val.roleId ? val.roleId = val.roleId.split(',') : '';
            // val.deptId = val.deptId.split(',');
            return val;
          });
          this.totalNumTow = data.total;
        } else {
          this.claimRecordListTwo = [];
          this.totalNumTow = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeList() {
      this.curType != 1 ? this.$refs.GeneralTable.getList() : this.$refs.GeneralTableTow.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm(`确认删除该${ this.curType == 0 ? '条理赔数据' : '条草稿箱数据' }?`, '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id);
          if (this.curType == 0) {
            insClaimsCaseRemove(ids).then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.changeList();
            }).catch(err => {
              this.$message({
                type: 'error',
                message: '失败!'
              });
            });
          } else {
            insNonClaimsCaseDraftRemove(ids).then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.changeList();
            }).catch(err => {
              this.$message({
                type: 'error',
                message: '失败!'
              });
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的数据！');
      }
    },
    exportData(e, v, pageNum, pageSize) {
      let currentData = {};
      currentData = JSON.parse(JSON.stringify(this.searchData));
      if (v.dangerDateBegin && v.dangerDateBegin.split(' ').length === 1) {
        v.dangerDateBegin = dayjs(v.dangerDateBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.dangerDateEnd = dayjs(v.dangerDateEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.reportCaseTimeBegin && v.reportCaseTimeBegin.split(' ').length === 1) {
        v.reportCaseTimeBegin = dayjs(v.reportCaseTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.reportCaseTimeEnd = dayjs(v.reportCaseTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (v.createTimeBegin && v.createTimeBegin.split(' ').length === 1) {
        v.createTimeBegin = dayjs(v.createTimeBegin).format('YYYY-MM-DD') + ' 00:00:00';
        v.createTimeEnd = dayjs(v.createTimeEnd).format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (e && e.length) {
        currentData.caseIdList = e.map(val => val.id).join(',');
      }
      insNonClaimsCaseExportAll({ ...currentData, ...v, current: pageNum, size: pageSize, condition: v.keyword });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleDataPermissions(key, perms) {
      return dataPermissions(key, perms);
    },
    handleDropdownClick(e, item) {
      if (e == 'edit') {
        this.$router.push({ path: '/routerClaimManagement/applyNonClaim?claimId=' + item.id });
      } else if (e == 'details') {
        this.$router.push({ path: '/routerClaimManagement/nonClaimDetails/' + item.id });
      } else if (e == 'del') {
        this.deleteItem([item]);
      } else if (e == 'evaluation') {
        this.handleEvaluation(item.id);
      } else if (e == 'reportingHistory') {
        this.isScheduling = true;
        this.editData = item;
      } else if (e == 'caseReopen') {
        this.isCaseReopen = true;
        this.editData = item;
      }
    },
    handleEvaluation(caseId) {
      findEvaluate(caseId).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.showEvaluationDialog = true;
          res.data.scoreEfficiency = parseInt(res.data.scoreEfficiency);
          res.data.scoreService = parseInt(res.data.scoreService);
          res.data.scoreTechnique = parseInt(res.data.scoreTechnique);
          this.curEvaluation = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleDatchCloseCase() {
      let available = this.$refs.GeneralTable.tableSelectionList?.filter(val => val.statusValue == 10);
      if (available && available.length) {
        this.availableCloseCaseIds = available.map(val => val.id);
        this.showDatchCloseCase = true;
      } else {
        this.$message.error('请选择可结案的理赔案件');
        return false;
      }
    },
    handleOptionPolicy(e) {
      // this.alarmDetails = JSON.parse(JSON.stringify(e));
      // this.isShowWhose = true;
      if (e) {
          this.showOptionPolicy = true;
          this.gettingPolicyList = [e];
        return false;
        if (!e.policyId) {
          this.showOptionPolicy = true;
          this.gettingPolicyList = [e];
        } else {
          this.$confirm(`确认认领该案件?`, '认领', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            insClaimsCaseAdopt({
              id: e.id,
              policyId: e.policyId
            }).then(res => {
              this.$message({
                type: 'success',
                message: '认领成功!'
              });
              this.changeList();
            }).catch(err => {
              this.$message({
                type: 'error',
                message: '失败!'
              });
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消认领'
            });
          });
        }
        return false;
      };
      let selectionList = this.$refs.GeneralTablePond.tableSelectionList ? this.$refs.GeneralTablePond.tableSelectionList.filter(val=> val.caseFollower == this.$store.state.userInfo.name) : [];
      if (!selectionList || !selectionList.length) {
        this.$message.error('请选择批量认领案件！');
        return false;
      }
      let available = selectionList?.filter(val => !val.policyId);
      if (available && available.length) {
        let num = 0;
        selectionList.map(val => {
          if (val.policyId) {
            insClaimsCaseAdopt({
              id: val.id,
              policyId: val.policyId
            }).then(res => {
              num += 1;
              if (num >= selectionList.length) {
                this.changeList();
              }
              // this.$message({
              //    type: 'success',
              //    message: '认领成功!'
              // });
              // this.changeList();
            }).catch(err => {
              num += 1;
              if (num >= selectionList.length) {
                this.changeList();
              }
            });
          }
        });
        this.gettingPolicyList = selectionList;
        this.showOptionPolicy = true;
        return false;
      } else {
        let num = 0;
        selectionList.map(val => {
          //    return{
          //    id: val.id,
          //    policyId: val.policyId
          // }
          insClaimsCaseAdopt({
            id: val.id,
            policyId: val.policyId
          }).then(res => {
            num += 1;
            if (num >= selectionList.length) {
              this.changeList();
            }
            // this.$message({
            //    type: 'success',
            //    message: '认领成功!'
            // });
            // this.changeList();
          }).catch(err => {
            num += 1;
            if (num >= selectionList.length) {
              this.changeList();
            }
          });
        });

        //   this.availableCloseCaseIds = this.$refs.GeneralTablePond.tableSelectionList.map(val => val.id);
      }
    },
    handleShowAlarm(e) {
      this.alarmDetails = JSON.parse(JSON.stringify(e));
      this.isShowAlarm = true;
    },
    handleDispatch(e) {
      this.alarmDetails = JSON.parse(JSON.stringify(e));
      this.isShowDispatch = true;
    },
    importClick() {
      console.log('导入');
      this.isImport = true;
    },
    handleElectronicPolicy(e) {
      this.editData = e;
      this.electronicVisible = true;
    },
    //获取批改历史
    getCorrectionHistory(item) {
      this.$refs.hisRd.getDataList(item);
      this.hisShow = true;
    },
    handleGoInquiryPath(e) {
      if (this.$route.meta.policyRoute) {
        this.$router.push({ path: `/routerPolicyManagement/${ this.$route.meta.policyRoute }/policyDetails/${ e.policyId }` });
      } else {
        this.$router.push({
          path: "/routerPolicyManagement/hostingDetails?id=" + e.policyId,
        });
      }
    },
    // 格式化限制数字文本框输入，只能数字或者两位小数
    input_num(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      if (decimal == 2) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      } else {
        // 只能输入4个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');
      }

      //如果有小数点，不能为类似 1.10的金额  
      // if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      // //如果有小数点，不能为类似 0.00的金额 
      // if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
      if (e.indexOf(".") <= 0 && e != "") {
        tiem[key] = parseFloat(e);
      }
      if (e > max) {
        const aa = e + '';
        tiem[key] = parseFloat(aa.slice(0, e.length - 1));
      }
    },
    handleGoPath(path) {
      this.$router.push({ path });
    },
    handleSelect(e) {
      this.curType = e;
      this.searchData.orderType = 21;
      this.searchData.isCasePool = false;
      this.changeList();
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 !important;
  .type-select {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: -10px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    .item {
      position: relative;
      padding: 13px 20px;
      color: #606266;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #409eff;
      }
    }
    .active {
      color: #4278c9;
      font-weight: bold;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: 4px;
        background-color: #4278c9;
      }
    }
  }
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
    .status {
      position: relative;
      padding-left: 18px;
      font-size: 14px;
      display: inline-block;
    }
    .status::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .status1 {
      color: #00bc0d;
    }
    .status1::after {
      background-color: #00bc0d;
    }
    .status2 {
      color: #0080ff;
    }
    .status2::after {
      background-color: #0080ff;
    }
    .status3 {
      color: #cccccc;
    }
    .status3::after {
      background-color: #cccccc;
    }
    .status4 {
      color: #fca118;
    }
    .status4::after {
      background-color: #fca118;
    }
  }
}
.reset-password {
  display: flex;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 12px;
  font-size: 14px;
}
::v-deep .el-dropdown,
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>